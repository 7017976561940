import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {MDBAnimation} from 'mdbreact';

export default props => {
  const {image: src, alt} = props;

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativeDirectory: {eq: "home-page/client-logos"}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              fixed(width: 160) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `);

  const match = React.useMemo(
    () =>
      data.allFile.edges.find(({node}) => `home-page/client-logos/${src}` === node.relativePath),
    [data, src]
  );

  return (
    <>
      <div className="col-6 col-md-4 col-lg-3 text-center">
        <div className="my-4 px-4 w-100">
          <MDBAnimation type="fadeInUp" reveal>
            <Img
              className="img-fluid"
              imgStyle={{objectFit: 'contain'}}
              placeholderStyle={{objectFit: 'contain'}}
              objectFit="contain"
              objectPosition="50% 50%"
              fixed={match.node.childImageSharp.fixed}
              alt={alt}
              fadeIn={false}
              loading="eager"
            />
          </MDBAnimation>
        </div>
      </div>
    </>
  );
};
