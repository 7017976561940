import React from 'react';
import {MDBAnimation} from 'mdbreact';

export default ({white = false}) => {
  const imgSuffix = white ? '-white' : '';
  return (
    <>
      <section className="numbers-section-home py-5">
        <div className="container py-2">
          <div className="row justify-content-center align-items-center">
            <div className="col-12">
              <MDBAnimation type="fadeInUp" duration="1s" reveal>
                <div className="heading latoBlack wow fadeInUp pb-2">Our journey since 2007</div>
              </MDBAnimation>

              <div className="numbers row mt-3">
                <MDBAnimation type="fadeInUp" duration="1.2s" reveal>
                  <div className="journey-box d-flex flex-row pt-4 pb-2 px-3 wow fadeInUp">
                    <div className="pl-2">
                      <img
                        className="home-journey__stat-img"
                        src={
                          require(`../../assets/images/home-page/graphs/journey/projects-delivered-icon${imgSuffix}.svg`)
                            .default
                        }
                        alt="projects delivered"
                      />
                      <div className="description latoRegular pt-3 mb-2">Projects Delivered</div>
                      <div className="font-size latoBlack mb-2">500+</div>
                    </div>
                  </div>
                </MDBAnimation>
                <MDBAnimation type="fadeInUp" duration="1.2s" reveal>
                  <div className="journey-box d-flex flex-row pt-4 pb-2 px-3 wow fadeInUp">
                    <div className="pl-2">
                      <img
                        className="home-journey__stat-img"
                        src={
                          require(`../../assets/images/home-page/graphs/journey/strong-team-icon${imgSuffix}.svg`)
                            .default
                        }
                        alt="strong team"
                      />
                      <div className="description latoRegular pt-3 mb-2">Strong Team</div>
                      <div className="font-size latoBlack mb-2">200+</div>
                    </div>
                  </div>
                </MDBAnimation>

                <MDBAnimation type="fadeInUp" duration="1.6s" reveal>
                  <div className="journey-box d-flex flex-row pt-4 pb-2 px-3 wow fadeInUp">
                    <div className="pl-2">
                      <img
                        className="home-journey__stat-img"
                        src={
                          require(`../../assets/images/home-page/graphs/journey/unicorn-icon${imgSuffix}.svg`)
                            .default
                        }
                        alt="unicorn"
                      />
                      <div className="description latoRegular pt-3 mb-2">Unicorns</div>
                      <div className="font-size latoBlack mb-2">04</div>
                    </div>
                  </div>
                </MDBAnimation>
                <MDBAnimation type="fadeInUp" duration="1.8s" reveal>
                  <div className="journey-box d-flex flex-row pt-4 pb-2 px-3 wow fadeInUp">
                    <div className="pl-2">
                      <img
                        className="home-journey__stat-img"
                        src={
                          require(`../../assets/images/home-page/graphs/journey/fortune-star-icon${imgSuffix}.svg`)
                            .default
                        }
                        alt="fortune star"
                      />
                      <div className="description latoRegular pt-3 mb-2">Fortune 500</div>
                      <div className="font-size latoBlack mb-2">03</div>
                    </div>
                  </div>
                </MDBAnimation>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
